@import './_breakpoints.css';
@value square-border from './_fontsAnimations.css';
@value text-color from './_colors.css';

.EmailOptInModal {
  & .Modal {
    padding: 0;
    width: 408px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  }

  & .EmailOptInModalContent {
    box-sizing: border-box;
    border-radius: square-border;
    width: 100%;
    color: text-color;
    padding: 24px 30px;
  }

  & .EmailOptInModalTitle {
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 27px;
    padding-bottom: 14px;
  }

  & .FormInnerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  & label {
    font-size: 10px;
    font-weight: 500;
  }

  & label::after {
    padding-left: 3px;
    color: #979797;
    content: 'Optional';
  }

  & .InputElement {
    border-radius: 8px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  & .ValidatorInput {
    margin-bottom: 0;
    flex-grow: 2;
  }

  & .ValidatorInput input {
    padding-right: 10px;
  }

  & .Error {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
    margin-top: 2px;
    color: #b10234;
  }

  & .FormSubmit {
    height: 40px;
    margin-bottom: 14px;
    flex-grow: 1;
  }

  & .PillButton {
    font-size: 14px;
    height: 40px;
    padding: 0 20px;
  }

  & .EmailOptInModalBodyWrapper {
    padding-top: 6px;
  }

  & .EmailOptInModalBodyHeader {
    font-size: 12px;
    font-weight: 900;
    padding-bottom: 13px;
  }

  & .EmailOptInModalBodyContentWrapper {
    padding-bottom: 9px;
  }

  & ul {
    margin: 0;
    padding: 0;
  }

  & .ElementWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
  }

  & .ElementIcon {
    width: 24px;
    height: 24px;
  }

  & .LeverageIcon {
    & .LeverageIcon-svg {
      height: 24px;
    }
  }

  & .ElementText {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    padding-left: 16px;
  }

  & .Footer {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }

  & .Link {
    text-decoration: none;
  }
}

@media (--small-screen) {
  .EmailOptInModal {
    & .Modal {
      width: 90vw;
    }
  }
}
