.Container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.Message {
  font-weight: 900;
  font-size: 16px;
}

.ActionLink {
  font-size: 12px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.Icon,
.Message,
.ActionLink {
  margin: 6px;
}
