.RequestATourAgentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .AccountIcon {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    & .AccountIcon-svg {
      height: 16px;
      width: auto;
    }
  }

  & .AgentName {
    font-size: 12px;
    line-height: 16px;
    margin: 7px 0 4px;
  }

  & .AgentInfo {
    font-size: 10px;
  }

  & .AgentInfoData {
    text-decoration: none;
  }

  & .Divider {
    margin: 0 6px;
    font-size: 4px;
    position: relative;
    bottom: 2px;
  }
}
