@import './_breakpoints.css';

.Wrapper {
  top: -20%;

  & .Dialog {
    max-width: 345px;
    border-radius: 14px;
    padding: 30px;
  }
}

.NotificationModalWithLink {
  /* 'min-width: 0;' on the parent allows a flex child to truncate */
  min-width: 0;
  font-size: 16px;

  & .TitleContainer {
    /* Truncate title text to match iOS: */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .Title {
      font-weight: 600;
      padding-bottom: 5px;
    }

    & .IconWrapper {
      margin-right: 10px;
      display: flex;

      & .GreenCheckIcon,
      & .GreenCheckIcon-svg {
        height: 30px;
        width: 30px;
        align-self: center;
      }
    }
  }

  & .Message {
    flex-basis: 3;
    margin-top: 5px;
    font-weight: 300;
  }
}

.Link {
  composes: strip-button-styles from './_common.css';
  cursor: pointer;
  text-decoration: underline;
}

@media (--small-screen) {
  .Wrapper {
    & .Dialog {
      width: 100vw;
    }
  }
}
