.SessionKeepAlive {
  font-weight: 200;

  & .ChildrenWrapper {
    max-width: 400px;
    margin: 60px auto 0;
  }

  & h2 {
    font-weight: 200;
    font-size: 24px;
  }

  & p {
    text-align: justify;
    margin-right: -80px;
  }

  & .ButtonRow {
    margin: 35px 0;
  }

  & .PillButton {
    width: 165px;
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 700px) {
  .SessionKeepAlive {
    & .ChildrenWrapper {
      max-width: calc(100% - 80px);
    }

    & p {
      margin-right: 0;
    }
  }
}
