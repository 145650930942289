@import './_breakpoints.css';
@value ch-black, ch-green, ch-electric-blue, ch-red, text-color, text-color-light from './_colors.css';
@value rounded-border from './_fontsAnimations.css';

.FindAgentForm {
  max-width: 668px;
  width: 100%;
  text-align: left;
  margin: 20px auto;
}

.FormHeader {
  color: ch-black;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
  padding-bottom: 17px;
  margin: 0 9px;
}

.TopImageModule {
  margin-bottom: 32px;

  & .ImageAndPropertyInfoContainer {
    display: flex;
    flex-direction: row;
    margin: 0 9px;

    & .TopImageModulePhoto {
      width: 105px;
      height: 71px;
      border-radius: rounded-border;
      display: inline-block;
      background-size: cover;
      background-position: center;
      position: relative;

      & .MlsLogoOverlay {
        position: absolute;
        height: 16px;
        width: 32px;
        bottom: 4px;
        left: 8px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    & .ImageNullStateIconContainer {
      width: 105px;
      height: 71px;
      border-radius: rounded-border;
      background-color: #eaeaea;
      display: flex;

      & .ImageNullStateIcon {
        width: 25px;
        height: 25px;
        margin: auto;
      }
    }

    & .PropertyInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 10px;
      align-self: center;

      & .PropertyInfoAddress {
        max-width: 325px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: text-color;
        text-align: left;

        & a {
          color: text-color !important;
          text-decoration: none !important;
        }

        font-size: 13px;
        font-weight: 300;
        line-height: 24px;
      }

      & .PropertyInfoBasic {
        color: text-color;
        display: flex;
        flex-direction: row;
        font-size: 13px;
        font-weight: 300;
        line-height: 24px;

        & .VerticalSeparator {
          margin: auto 8px;
          border-right: 1px solid #d7d7d7;
        }

        & .PropertyDetailsWrapper {
          display: flex;

          & .PropertyDetailsItem {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.FullWidth {
  width: 100%;
}

.FindAgentPromptContainer {
  padding-bottom: 17px;
}

.FindAgentPromptHeaderWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.FindAgentPromptHeader {
  color: ch-black;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
}

.NormalHeaderText {
  font-weight: 500;
  font-size: 12px;
}

.FindAgentPrompt {
  display: flex;
  flex-direction: row;
  max-width: 200px;

  & .CheckboxInput {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  /* overriding checkbox manual background color to match invision */
  & .CheckBoxInputSelected {
    border-color: #0b6efd !important;
    background: #0b6efd !important;
  }

  & .CheckboxCheck {
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    border: unset;
    top: 9px;
    left: 10px;
  }
}

/* form required message will be refined in sprint of 4.3. putting this one in to explicitly state this is required */
.AnswerIsRequiredText {
  color: ch-red;
  font-size: 12px;
  text-align: left;
  font-weight: 300;
  margin-left: 5px;
}

.BottomInfoModule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  & .InfoIcon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  & .BottomInfoModuleTextContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 300px;

    & .BottomInfoModuleHeader {
      font-size: 12px;
      font-weight: 900;
      line-height: 20px;
    }

    & .BottomInfoModuleBody {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.FormElementContainer {
  display: flex;
}

.FormElementWrapper {
  width: 325px;
  margin: 0 9px;
}

.Label {
  color: ch-black;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0 !important;
}

.PreferredSellingPriceWrapper {
  width: 325px;
  margin: 0 9px;
}

.PreferredSellingPriceWrapper,
.TooltipWrapper {
  margin: 0 9px;
  width: 325px;
}

.TextAreaWrapper {
  position: relative;
  margin: 0 9px 5px;
  width: 325px;
}

.TextAreaWrapper .InputWrapper {
  outline: 0;
}

.TextAreaWrapper .InputElement {
  font-size: 14px;
  font-family: Avenir;
  color: text-color;
}

.TermsLink {
  text-align: left;
  font-size: 12px;
}

.PreferredSellingPriceWrapper .InputWrapper .InputElement {
  margin-bottom: 10px;
}

.ValidatorInput {
  margin-bottom: 0;
}

.TooltipWrapper {
  margin-bottom: 14px;
}

.SubmitButtonWrapper {
  display: flex;
  justify-content: center;

  & .SubmitButtonDisabled {
    background: #c8c8c8 !important;
  }
}

.PillButton {
  margin-top: 50px;
  height: 46px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 261px;
}

.LearnMoreAside {
  color: #0269f7;
}

@media (--large-screen) {
  .FormElementContainer {
    justify-content: space-between;
  }

  .FindAgentForm {
    max-width: 500px;
    text-align: center;
    margin: 120px auto 0;
  }

  .FormElementWrapper,
  .PreferredSellingPriceWrapper {
    width: 235px;
  }

  .FullWidth {
    width: 100%;
  }

  .TextAreaWrapper {
    width: 100%;
  }

  .PillButton {
    width: 261px;
    height: 46px;
  }
}

@media (--small-screen) {
  .FindAgentForm {
    max-width: none;
    /* give enough space for the bottom module to be not hidden by the floating action buttons */
    margin-bottom: 130px;
    position: relative;
  }

  .FormElementContainer {
    flex-direction: column;
  }

  .FormHeader {
    font-size: 12px;
    margin: 24px auto 0;
    max-width: 325px;
    padding: 0 10px 17px;
  }

  .FormContentHeaderText {
    margin-top: 0;
  }

  .FormElementWrapper,
  .PreferredSellingPriceWrapper {
    margin: 0 auto;
    max-width: 400px;
  }

  .FindAgentPromptHeaderWithTooltip {
    flex-direction: column;
    margin-bottom: 10px;

    & .LearnMoreAside {
      margin-left: 0;
    }
  }

  .HcAvmExplanation {
    margin: 0;
  }

  .TopImageModule {
    & .ImageAndPropertyInfoContainer {
      padding-bottom: 14px;

      & .PropertyInfo {
        & .PropertyInfoAddress {
          font-size: 12px;
        }
      }
    }
  }

  .TooltipWrapper,
  .TextAreaWrapper {
    margin: 0 auto 14px;
    max-width: 400px;
  }

  .MobileSubmitButtonInnerWrapper {
    display: flex;
    margin-top: 82px;
    padding: 12px 26px;
    justify-content: center;
    width: 100%;
    align-items: center;
    background: ch-black;
    position: fixed;
    bottom: 0;
    z-index: 10;
  }

  .PillButton {
    width: 165px;
    margin: 0 20px;
  }

  .MobileSubmitButtonInnerWrapper .CancelButton {
    background: transparent !important;
  }

  .TopImageModule,
  .FindAgentPromptContainer {
    max-width: 325px;
    margin: auto;
  }

  .FullWidth {
    max-width: none;
  }

  .TextAreaWrapper .InputElement {
    font-size: 16px !important;
  }

  .TooltipContent {
    transform: translate(-31vw, -10vh) !important;
  }
}

@media (--xx-small-screen) {
  .FindAgentForm {
    max-width: 100%;
    /* give enough space for the bottom module to be not hidden by the floating action buttons */
    margin-bottom: 130px;
  }

  .FormElementWrapper,
  .PreferredSellingPriceWrapper,
  .TooltipWrapper,
  .TextAreaWrapper {
    width: calc(100% - 20px);
  }

  .FormHeader {
    width: calc(100% - 20px);
    margin: 24px 10px 0;
    padding: 0 0 17px;
  }

  .FormContentHeaderText {
    margin-top: 0;
  }

  .PillButton {
    width: 140px;
  }
}
