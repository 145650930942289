@import './_breakpoints.css';

.NoMLSModalChase.SmallModal {
  & .Modal {
    border-radius: 4px;
    width: 400px;
    padding: 20px 40px 30px;
    font-weight: 200;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  }

  & .ModalGraphic {
    width: 150px;
  }

  & p {
    width: 280px;
    margin: 30px auto;
    max-width: 100%;

    &:first-child {
      width: 220px;
    }
  }

  & .CloseIcon {
    display: none;
  }
}

.PillButton {
  font-weight: 400;
  min-width: 160px;
  width: auto;
}

@media (--x-small-screen) {
  .NoMLSModalChase.SmallModal .Modal {
    width: calc(100% - 40px);

    & .CloseIcon {
      display: block;
    }
  }
}
