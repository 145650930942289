@import './_breakpoints.css';

.RequestATourModal {
  & .Modal {
    padding: 20px 28px 33px;
    box-sizing: border-box;
    max-width: 360px;
    width: 100%;
  }

  &.autoWidth .Modal {
    width: auto;
    padding-bottom: 22px;
  }

  & .CloseIcon {
    transform: scale(0.5);
  }
}

.RequestATourModalWrapper {
  & .Heading {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin: 0 0 3px;
  }

  & .SubHeading {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 0 0 14px;
  }
}

.AgentSelection {
  margin-top: 20px;

  & .AgentButtonTopRow {
    margin-bottom: 18px;
  }

  & .AgentButton {
    font-weight: 400;
    font-size: 14px;
    height: 45px;
  }
}

.AgentInfoHeading {
  margin: 22px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  max-width: 225px;
}

@media (--small-screen) {
  .Screen {
    background: rgb(0 0 0 / 70%) !important;
    position: relative;
  }

  .AgentButton {
    max-width: 100%;
    width: 100%;
  }

  .RequestATourModal {
    &.SmallModal.autoWidth {
      align-items: center;
    }

    & .Modal {
      max-width: 100vw;
      width: 100vw;
    }

    &.autoWidth .Modal {
      width: auto;
    }
  }
}
