.CantFindAddressText {
  & h2 {
    font-size: 24px;
    font-weight: 100;
    margin: 15px 0;
  }

  & dl {
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 16px;
  }

  & dt {
    font-weight: 400;
    font-size: 12px;
    display: inline;
    margin-right: 5px;
  }

  & dd {
    font-weight: 100;
    font-size: 12px;
    display: inline;
    margin: 0;
  }
}
