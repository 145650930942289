@import '../_breakpoints.css';
@value text-color from '../_colors.css';

.YourTeamTabs {
  border-radius: 14px;
}

.LOAndAgentCardTopNavTabList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 77px;
  background: #e9e9e9;
  border-radius: 14px 14px 0 0;
}

.LONavButton {
  border-radius: 14px 0 0;
}

.AgentNavButton {
  border-radius: 0 14px 0 0;
}

.NavButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  position: relative;

  & .Label {
    color: #4a4a4a;
    font-size: 12px;
    line-height: 11px;
    font-weight: 800;
    padding: 30px 10px 0;
  }

  & .SubText {
    color: #4a4a4a;
    font-weight: normal;
    line-height: 11px;
    font-size: 11px;
    padding-top: 6px;
    height: 17px;
    min-height: 17px;
  }
}

.ActiveNav {
  background-color: #fff;
  cursor: initial;
}

.OneCardHeader {
  width: 100%;
  text-align: center;
  border-radius: 0.875rem 0.875rem 0 0;
  padding-bottom: 20px;
}

.PhotoColumn {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #e9e9e9;
  background-color: #e9e9e9;
}

.Photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  cursor: pointer;
}

.PhotoColumnActiveNav {
  border-color: #fff;
  background-color: #fff;
}

.FindAgentAvatar {
  width: 50px;
  height: 50px;
}

@media (--x-small-screen) {
  .LOAndAgentCardTopNavTabList,
  .LONavButton,
  .AgentNavButton {
    border-radius: 0;
  }
}
