.RequestATourShare {
  padding-top: 6px;

  & .Error {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  & .Label {
    font-size: 12px !important;
  }

  & .CopyableField,
  & .InputElement {
    margin-bottom: 20px;
  }

  & .CopyableField {
    & .FlatButtonLabel,
    & .InputElement {
      font-size: 12px;
    }

    & .CopyIcon,
    & .CopyIcon svg {
      width: 18px;
    }
  }
}
