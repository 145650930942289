@import './_breakpoints.css';
@value ch-black, ch-green, ch-electric-blue, ch-red, text-color from './_colors.css';

.PageWrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  z-index: 101;
}

.CloseIcon {
  position: absolute;
  top: 36px;
  right: 42px;
  cursor: pointer;
  z-index: 3;

  & svg :global .element-to-fill {
    fill: #fff;
  }
}

.CloseIcon .CloseIcon-svg {
  height: 29px !important;
  width: 29px !important;
}

.PageContainer {
  padding: 0 28px;
}

.BackgroundGradientOverlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0.85;
  width: 100%;
  height: 173px;
}

.HeroWrapper {
  background-image: url('../assets/images/find-agent-bg-tablet.jpg');
  width: 100%;
  height: 173px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.LineBreak {
  display: none;
}

.HeroContentWrapper {
  padding: 72px 0 0 72px;
  position: relative;
  z-index: 3;
}

.HorizontalSeparator {
  margin: 0 0 5px;
  width: 50px;
  border-bottom: 2px solid #e9e8e8;
  border-radius: 2px;
}

.HeroText {
  color: #fff;
  font-size: 22px;
  font-weight: 900;
  z-index: 3;
  position: relative;
}

.SubHeroText {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding-top: 4px;
}

.FormWrapper {
  margin: auto;
}

@media (--large-screen) {
  .PageWrapper {
    flex-direction: row;
  }

  .CloseIcon {
    & svg :global .element-to-fill {
      fill: #626262;
    }
  }

  .PageContainer {
    padding: 0 28px;
  }

  .HeroWrapper {
    width: 636px;
    min-width: 636px;
    height: auto;
    background-image: url('../assets/images/img-RF-cropped.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .BackgroundGradientOverlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0.8;
    width: 636px;
    min-width: 636px;
    height: auto;
  }

  .HeroContentWrapper {
    padding: 165px 59px 0;
  }

  .HorizontalSeparator {
    width: 80px;
    border-bottom: 5px solid #e9e8e8;
    margin-bottom: 30px;
  }

  .HeroText {
    font-size: 60px;
  }

  .SubHeroText {
    font-size: 28px;
    padding-top: 28px;
  }

  .FormWrapper {
    justify-content: center;
    max-width: 566px;
    min-height: 100vh;
  }

  .LineBreak {
    display: block;
  }
}

@media (--small-screen) {
  .FormWrapper {
    width: 90%;
    margin: 0 auto;
  }

  .CloseIcon {
    display: none;
  }

  .HeroContentWrapper {
    margin: 72px auto 0;
    max-width: 325px;
    padding: 0 10px 17px;
  }
}

@media (--xx-small-screen) {
  .HeroContentWrapper {
    width: 90%;
    padding: 0 0 17px;
  }
}
