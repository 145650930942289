.TooltipPortalContentWrapper {
  position: fixed;
  /* Above all other page elements */
  z-index: 101;
  height: 16px;
  width: 72px;
  background-color: #f0f0f0;
  display: inline-block;
  border-radius: 32px;
  /* top and left positioning set by JS to match viewport positioning of tooltip trigger */
}

.TooltipContent {
  display: flex;
  position: relative;
  /* Initially hide, to be shown after we check the dimensions and reposition
     completely within the viewport if necessary */
  visibility: hidden;
  flex-wrap: wrap;
  background-color: white;
  border: none;
  border-radius: 4px;
  padding: 6px;
  box-sizing: border-box;
  max-width: calc(100vw - 40px);
  color: inherit;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}

.TooltipPortalContentWrapper .TooltipContent {
  visibility: visible;
}

.TooltipArrow {
  position: absolute;
  left: calc(50% - 10px);
  font-size: 32px;
  width: 20px;
  text-align: center;
  transform: scaleX(1.5);
  border: none;
  line-height: 8px;
  color: white;
  padding-right: 32px;
}

.SearchTooltipArrow {
  position: absolute;
  font-size: 16px;
  width: 20px;
  text-align: center;
  transform: rotate(90deg);
  border: none;
  line-height: 8px;
  color: white;
  padding-right: 40px;
  padding-top: 58px;
  margin-left: -8px;
}

.SearchTooltipPortalContentWrapper {
  position: fixed;
  /* Above all other page elements */
  z-index: 101;
  height: 16px;
  width: 56px;
  background-color: #f0f0f0;
  display: inline-block;
  border-radius: 32px;
  /* top and left positioning set by JS to match viewport positioning of tooltip trigger */

  & .TooltipContent {
    visibility: visible;
  }
}
