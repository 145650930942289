:root {
  --home-sales-modal-header-height: 54px;
}

.ModalWithCloseIcon {
  /* override since ModalHeader is defined as children */
  padding: 0 !important;
}

.MobileTopBar {
  display: none;
}

.ModalHeader {
  padding: 15px;
  display: flex;
  height: var(--home-sales-modal-header-height);
  box-sizing: border-box;

  & .CloseIconButton {
    height: 18px;
    width: 18px;
    margin-right: 20px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    & svg :global .element-to-fill {
      fill: white;
    }
  }

  & .ModalTitle {
    font-size: 16px;
    font-weight: 900;
    text-align: left;
    margin: 0;
  }

  & .IconContainer {
    display: flex;
    margin-left: auto;

    & .SeeAllButton {
      margin-right: 15px;
      height: 24px;
      width: 24px;
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
    }

    & .MapViewButton {
      height: 24px;
      width: 24px;
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
    }

    & .InActiveButton {
      opacity: 0.7;
    }
  }
}

.Divider {
  border-top: 1px solid #e6e6e6;
  margin: 25px 0 0;
}

.CompSalesCard {
  margin: auto !important;
}

.SearchMap {
  height: calc(100vh - var(--home-sales-modal-header-height));
}
