@import './_breakpoints.css';

.CantFindAddressModal {
  & .ModalBody {
    padding: 10px 25px 120px;
    max-width: 700px;
    margin: auto;
  }

  & .ClickableLogo {
    display: none;
    margin: auto;

    & :global svg .element-to-fill {
      fill: #20203f;
    }
  }
}

@media (--small-screen) {
  .CantFindAddressModal {
    & .Modal {
      padding-left: 0;
      padding-right: 0;
    }

    & .ClickableLogo {
      display: flex;
    }

    & .CantFindAddressModalClickableLogo .ClickableLogo {
      z-index: 3;
      position: fixed;
      top: 12px;
      right: 10px;
    }
  }
}
