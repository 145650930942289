@import './_breakpoints.css';
@value text-color from './_colors.css';

.SignupPage {
  height: 100%;
  width: 100%;
}

.SignupForm {
  width: 400px;
  max-width: calc(100vw - 20px);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
}

.SignUpOrLoginLink {
  margin-bottom: 12px;
}

.TogglePasswordVisibilityButton {
  top: 27px;
  height: 40px;
}

.RequiredFieldsMessage {
  text-align: left;
  font-style: italic;
  font-size: 12px;
  font-weight: 100;
  margin: 10px 0;
}

.FormSubmit {
  margin: 30px 0 16px;
}

.input {
  text-align: left;
}

.ValidatorInput {
  margin-bottom: 15px;
}

.chevron {
  font-size: 8px;
  bottom: 0.1em;
}

.TermsCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .Checkbox {
    min-width: auto;
    padding-right: 0;
  }
}

.TermsLink {
  text-align: left;
  font-size: 14px;
}

.ValidationIndicator {
  display: none;
}

.TermsRequiredMessage {
  font-weight: 900;
  text-align: left;
  font-size: 14px;
  margin: 6px 0 0 35px;
}

.SkipForNow {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 30px;

  & button {
    cursor: pointer;
    border: none;
    background: none;
    color: #0269f7;
  }
}

@media (--small-screen) {
  .chevron {
    margin-left: 0.175em;
  }
}
