.Wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 338px;
}

.Title {
  width: 88%;
  font-weight: 800;
  font-size: 24px;
  line-height: 119.6%;
  letter-spacing: -0.3333px;
  color: #4a4a4a;
}

.OptInDescription {
  width: 88%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4a4a4a;
}

.ModalButton {
  cursor: pointer;
  width: 88%;
  height: 45px;
  left: 25px;
  top: 148px;
  border-radius: 8px;
  border: none;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

.EditSearch {
  margin-top: 20px;
  margin-bottom: 7px;
  width: 88%;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #4a4a4a;
}

.Explore {
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 7px;
  align-self: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0269f7;
}
