.modal-container .Modal {
  width: 350px;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 26px 0 rgb(0 0 0 / 30%);
  justify-content: center;
  max-width: 98vw;
  padding: 30px 0;
}

.Header {
  height: 60px;
  width: auto;
  color: #626262;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  padding: 0 25px;
}

.InnerContent {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
}

.NumberBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #f0f0f0;
  text-align: center;
  color: #626262;
  font-weight: 700;
  margin-right: 20px;
}

.ContentLine {
  margin-top: 0;
}

.NumberList {
  float: left;
  width: 50px;
}

.TextGrid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding: 10px 20px;
  margin-bottom: 5px;
}

.InputElement {
  border-radius: 20px;
  transition: border-radius 200ms ease-out;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 16px;

  &::placeholder {
    color: inherit;
    font-weight: 200;
    font-size: 14px;
    text-align: left;
  }
}

.ArrowIcon,
.ArrowIcon-svg {
  height: 15px;
  width: auto;
}

.ArrowIcon {
  display: flex;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
}

.ArrowIcon-svg {
  color: #fff;
  transform: rotate(180deg);
}

.circle {
  composes: strip-button-styles from './_common.css';
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 7px 1px rgb(0 0 0 / 13%);
}

.SearchBar {
  position: relative;
  margin-top: 15px;
  padding: 0 20px;
  /* Hardcoding so that the overall modal height is unchanged before/after adding the inline border
   * style on hover */
  height: 44px;

  & .circle {
    display: flex;
    position: absolute;
    right: 27px;
    top: 6px;
    z-index: 10;
    background: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    text-align: left;
  }
}

.Dismiss {
  height: 16px;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding-top: 20px;
}

.DismissButton {
  composes: strip-button-styles from './_common.css';
  text-decoration: underline;
  cursor: pointer;
}
