@import './_breakpoints.css';
@value text-color, modal-overlay from './_colors.css';
@value rounded-border, box-shadow-long from './_fontsAnimations.css';

.UpdateEmailModal .Modal {
  padding: 0;
  max-width: 433px;
  width: 100%;
  /* For the border radius to take effect */
  overflow: hidden;

  & .MobileTopBar {
    background-color: transparent;
  }

  & .DesktopCloseIcon svg :global .element-to-fill,
  & .MobileCloseIcon svg :global .element-to-fill {
    fill: white;
  }
}

.Body {
  padding: 20px;
  position: relative;
  width: 100%;
  max-width: 433px;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;

  & .ConfirmEmailButton {
    margin-top: 20px;
    width: 211px;
  }

  & .UpdateEmailSuccessText {
    font-size: 12px;
    font-weight: 300;
    margin-top: 15px;
  }

  & .NewEmailAddress {
    font-size: 12px;
    font-weight: 900;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  & .EmailUpdateSuccessCheckMarkIcon .EmailUpdateSuccessCheckMarkIcon-svg {
    width: 45px;
    height: 45px;
  }
}

.CloseIcon {
  display: none;
  top: 8px;
  right: 10px;

  /* stylelint-disable-next-line no-descending-specificity */
  & :global .element-to-fill {
    fill: #fff;
  }
}

.Header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 197px;
  color: #fff;
  background: linear-gradient(93.85deg, #20203f 0%, #3a3a79 100%);
  position: relative;
  text-align: center;
  padding: 24px;
}

.Title {
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  margin: 0;

  & .EmailIcon .EmailIcon-svg {
    height: 71px;
    width: 78px;
  }

  & .TitleText {
    margin-top: 10px;
  }
}

.SubTitle {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-top: 15px;

  & .UserEmail {
    font-weight: 900;
  }
}

@media (--x-small-screen) {
  .CloseIcon {
    display: flex;
    position: absolute;
  }

  .Body {
    max-width: 100vw;
  }
}
