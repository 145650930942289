@import './_breakpoints.css';
@value square-border from './_fontsAnimations.css';
@value text-color from './_colors.css';

.AuthBlockingModal {
  & .Modal {
    padding: 0;
    width: 408px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%);
  }
}

.AuthBlockingModalContent {
  box-sizing: border-box;
  border-radius: square-border;
  width: 100%;
}

.AuthBlockingModalTitle {
  padding: 35px 20px 17px;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.AuthBlockingModalBody {
  text-align: center;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.AuthBlockingModalFooter {
  padding: 33px 0 41px;
  text-align: center;

  & .PillButton {
    font-size: 14px;
    font-weight: 900;
    width: 200px;
    max-width: 200px;
  }
}

@media (--x-small-screen) {
  .AuthBlockingModal {
    & .Modal {
      width: 90vw;
    }
  }

  .AuthBlockingModalTitle {
    padding-top: 40px;
  }
}
