/**
  * NOTE: Please follow best practices for accessibility outlined in
  * https://medium.com/@sheribyrnehaber/designing-toast-messages-for-accessibility-fb610ac364be
*/

:root {
  --animation-duration: 0.35s;
  --animation-curve-default: cubic-bezier(0.4, 0, 0.2, 1);
  --toast-background-color: rgb(33 33 33);
  --toast-border-radius: 2px;
  --toast-color: rgb(255 255 255);
  --toast-horizontal-offset: 24px;
  --toast-vertical-offset: 14px;
  --z-index-higher: 200;
}

.toast {
  align-items: center;
  background-color: var(--toast-background-color);
  border-radius: var(--toast-border-radius);
  color: var(--toast-color);
  display: flex;
  padding: 0;
  position: fixed;
  bottom: 0;
  right: var(--toast-horizontal-offset);
  left: var(--toast-horizontal-offset);
  margin: var(--toast-vertical-offset) auto 0;
  text-align: center;
  transition: all var(--animation-duration) var(--animation-curve-default)
    var(--animation-duration);
  z-index: var(--z-index-higher);

  &:not(.active) {
    transform: translateY(100%);
  }

  &.active {
    transform: translateY(0%);
    padding: 14px 24px;
    z-index: 101;
  }

  & .label {
    flex-grow: 1;
    font-size: 14px;
  }
}
