@import './_breakpoints.css';

.RequestATourSuccessNotification {
  & .NotificationDialog {
    width: auto;
  }
}

.GreenCheckIcon,
.GreenCheckIcon-svg {
  height: 30px;
}

.GreenCheckIcon {
  margin-right: 8px;
}

.SuccessHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
  max-width: 225px;
}

.SuccessSubHeading {
  max-width: 226px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-weight: 300;
}

.WatchListLink {
  text-decoration: none;
}

@media (--desktop-screen) {
  .NotificationDialog {
    width: auto;
  }
}