@import '../_breakpoints.css';
@value text-color from '../_colors.css';

.LOCardContentWithLODataContainer,
.LOCardContentWithLODirectLeadSubmission {
  & .TopSection {
    flex-direction: row;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: text-color;
    margin-bottom: 0;
    pointer-events: none;
  }

  & .AgentName,
  & .mlsId {
    font-weight: normal;
    font-size: 10px;
    margin-top: 0;
  }

  & .DotSeparator {
    font-size: 7px;
    padding: 0 5px;
  }

  & .PhoneAndEmail {
    display: flex;
    font-size: 10px;
    line-height: 14px;
    font-weight: normal;
    white-space: nowrap;
  }

  & .PhoneAndEmail a {
    color: text-color;
  }

  & .AdCopy {
    padding-top: 18px;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
  }

  & .BottomButton {
    height: 46px;
    min-height: 46px;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    margin: 21px auto 0;
  }
}

.LOCardContentWithLODirectLeadSubmission {
  /* Consistent height between the "form" state and the "submission success" state */
  min-height: 225px;
  display: flex;
  flex-direction: column;

  & .GenericLOInfo {
    margin-bottom: 20px;
  }

  & .FormWrapper h2 {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: -0.2px;
    margin: 0 0 6px;
  }

  & .LOCustomLink {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.2px;
    margin-bottom: 22px;
  }

  /* Textarea */
  & .InputElementContainer {
    display: flex;
    flex-direction: column-reverse;

    & .InputElement {
      border: 1px solid #c2c2c2;
      border-radius: 6px;
    }

    & .Label {
      font-size: 12px;
      font-weight: 800;
      letter-spacing: -0.2px;
      margin-bottom: 6px;
    }
  }

  & .Error {
    font-size: 12px;
    color: #b10234;
    font-weight: 300;
    margin: 5px 0 0 2px;
  }

  & .PillButton.Disabled {
    background: #c8c8c8 !important;
  }

  & .SubmittedMessage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-10px);

    & svg {
      width: 50px;
    }

    & h2 {
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.2px;
      margin: 0;
    }
  }
}

.LOCardContentWithNoLODataContainer {
  & .GenericLOInfo {
    padding-left: 0;
    pointer-events: initial;
  }

  & .TopSection {
    flex-direction: row;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: text-color;
    margin-bottom: 0;
  }

  & .AgentName,
  & .AgentSubtitle,
  & .mlsId {
    font-weight: normal;
    font-size: 10px;
    margin-top: 0;
  }

  & .DotSeparator {
    font-size: 7px;
    padding: 0 5px;
  }

  & .PhoneAndEmail {
    display: flex;
    font-size: 10px;
    line-height: 14px;
    font-weight: normal;
    white-space: nowrap;
  }

  & .PhoneAndEmail a {
    color: text-color;
  }

  & .AdCopy {
    padding-top: 18px;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
  }

  & .LenderCTA {
    width: 100%;
    margin: 21px auto 18px;
  }

  & .BottomButton {
    height: 46px;
    min-height: 46px;
    font-size: 14px;
    max-width: none;
  }

  & .ButtonWrapperForSingleCTA {
    margin-top: 21px;
  }
}

@media (--tablet-screen-and-smaller) {
  .ButtonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .LOCardContentWithNoLODataContainer {
    & .ButtonWrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 18px auto;
    }
  }
}

@media (--small-screen) {
  .LOCardContentWithLODataContainer {
    & .AgentName {
      line-height: 14px;
    }
  }
}

@media (--x-small-screen) {
  .LOCardContentWithLODataContainer,
  .LOCardContentWithLODirectLeadSubmission {
    & .PhoneAndEmail {
      max-width: 100%;
    }

    & .GenericLOInfo {
      padding: 0;
    }
  }

  .LOCardContentWithNoLODataContainer {
    & .PhoneAndEmail {
      max-width: 100%;
    }

    & .mlsId {
      line-height: 16px;
    }

    & .GenericLOInfo {
      margin: 0 auto;
      padding: 0;
    }
  }
}
