@import './_breakpoints.css';

.LearnMoreAside {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  text-decoration: underline;
}
