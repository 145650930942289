.BrokerageContactConfirmModal {
  & .Modal {
    height: 272px;
    width: 364px;
    overflow: hidden;
  }

  & .ModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .ModalText {
      font-size: 16px;
      font-weight: 900;
      text-align: center;
      padding: 0 50px;
      margin-top: 40px;
    }

    & .PillButton {
      margin: 35px 0;
      height: 46px;
      width: 306px;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .WhiteCheckIcon {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(1.8);
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
      }

      & .WhiteCheckIcon::after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        left: 3px;
        top: -1px;
        width: 6px;
        height: 10px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        transform-origin: bottom left;
        transform: rotate(45deg);
      }
    }

    & .Auth {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 24px;

      & .SignUpLink {
        font-weight: 600;
        text-decoration: none;

        & .CaratIcon {
          position: relative;
          top: 4px;
        }

        & .CaratIcon-svg {
          transform: scale(0.65);
        }
      }
    }
  }
}
