@import './_breakpoints.css';

.BrokerageModal {
  /* Allows modal to appear above PropertyCard, CantFindAddressModal */
  z-index: 103;

  & .Modal {
    padding: 0;
    border-radius: 14px !important;
    /* Allows modal to appear above PropertyCard, CantFindAddressModal */
    z-index: 103;
  }

  & .Modal.ModalWithCloseIcon {
    padding-top: 0;
  }

  & .DesktopCloseIcon svg :global .element-to-fill,
  & .MobileCloseIcon svg :global .element-to-fill {
    fill: white;
    opacity: 0.6;
  }

  & .Header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px 14px 0 0;
    height: 212px;
    color: #fff;
    background: linear-gradient(104.11deg, #5840ea 0%, #3a61de 100%);
  }

  & .CloseIcon path:first-of-type {
    fill: #fff;
    opacity: 0.6;
  }

  & .Link {
    text-decoration: underline;
    padding-left: 5px;
  }

  & .ComeHomeLogo {
    width: 327px;
    height: auto;
  }
}

.ModalPositioner {
  &.ModalPositionerOversize .ScrollableContainer {
    overflow: auto;
    align-items: center;

    & .Modal {
      top: 0;
      margin-bottom: 0;
    }
  }
}

.TextContent {
  max-width: 434px;
  margin: 30px auto;
  padding: 0 54px;
  font-size: 14px;
  line-height: 20px;
}

.TopContent {
  font-weight: 600;
}

.BottomContent {
  font-weight: 500;
}

/* Allows modal to appear above PropertyCard, CantFindAddressModal */
.Screen {
  z-index: 102;
}

@media (--small-screen) {
  .BrokerageModal {
    & .Modal {
      padding: 0;
      border-radius: 14px;
      overflow: hidden;
    }

    & .ComeHomeLogo {
      width: 272px;
      height: auto;
    }

    & .Header {
      height: 196px;
    }

    & .Link {
      word-break: break-all;
    }

    & .MobileTopBar {
      background: linear-gradient(104.11deg, #5840ea 0%, #3a61de 100%);
    }
  }

  .TextContent {
    margin: 26px auto;
    padding: 0 26px;
  }
}

@media (--xxx-small-screen) {
  .BrokerageModal {
    & .ComeHomeLogo {
      width: 220px;
      height: auto;
    }

    & .Header {
      height: 140px;
    }
  }
}
