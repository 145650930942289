@import '../_breakpoints.css';
@value text-color from '../_colors.css';

.ButtonWrapper {
  margin-top: 25px;

  & .HelpText {
    font-weight: 900;
    font-size: 12px;
    color: text-color;
    margin-bottom: 14px;
  }

  & .OptionButton,
  & .LenderCTA,
  & .LenderCTAButton {
    text-align: left;
    min-width: 0;
    min-height: initial;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: auto;
    height: auto;
    display: block;
    word-wrap: break-word;
  }

  & .PillButton {
    width: auto;
    max-width: 326px;
  }

  & .OptionButton,
  & .LenderCTAButton {
    border-radius: 35px;
    margin-bottom: 14px;
    padding: 5px 10px;
  }

  & .LenderCTA {
    margin: 0;
    min-width: 125px;
  }

  & .BottomButton {
    width: 100%;
    height: 46px;
    min-height: 46px;
    font-size: 14px;
    margin-top: 11px;
  }

  & .BtnText {
    width: auto;
  }
}

@media (--tablet-screen-and-smaller) {
  .ButtonWrapper {
    width: 100%;
    margin: 18px auto;

    & .PillButton {
      max-width: 100%;
    }
  }
}
