@import './_breakpoints.css';
@import './_constants.css';
@value box-shadow-soft, square-border from './_fontsAnimations.css';

.Wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /** z-index is 1000 here, so that saved property
    * notification is on the top of property page modal
    * on map search page */
  z-index: 1000;
}

.Dialog {
  box-shadow: box-shadow-soft;
  box-sizing: border-box;
  border-radius: square-border;
  background: white;
  color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin: auto;
  /* Window size, minus 2x the size of the filter buttons (and 8px padding),
   * so it is centered without overlapping the filter buttons: */
  max-width: calc(
    100vw - var(--mobileControlButtonWidth) - var(--mobileControlButtonWidth) -
      8px
  );
  width: 248px;
}

.CloseIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

@media (--desktop-screen) {
  .Dialog {
    max-width: 460px;
    width: calc(100% - 20px);
    padding: 20px;
  }
}
