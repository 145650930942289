.TermsAndConditionsModal {
  & .Modal {
    padding: 28px 26px 39px;
    margin-left: 18px;
    margin-right: 18px;
  }

  & .TextContent {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    max-width: 357px;
    font-weight: 300;
    margin: auto;
  }

  & .TextContent + .TextContent {
    margin-top: 28px;
    max-width: 320px;
  }

  & .Link {
    text-decoration: none;
  }

  & .LogoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }

  & .CHLogo {
    height: 39px;
    width: auto;
  }

  & .ButtonRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .PillButton {
    margin-top: 32px;
    max-width: 125px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.TermsAndConditionsModalWells {
  & .Modal {
    padding: 0;
    width: 375px;
  }

  & .Link {
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  & .ButtonRow {
    display: flex;
    row-gap: 19px;
    flex-direction: column;
    align-items: center;

    & .ExternalLink {
      color: #d71e28;
      font-size: 12px;
    }
  }

  & .AgreementFormCard {
    padding: 32px 24px 24px;

    & .LogoWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;

      & .CHLogo {
        height: 39px;
        width: auto;
      }
    }

    & h2 {
      text-align: center;
      font-size: 18px;
      font-weight: 900;
      margin-bottom: 21px;
    }

    & .EmailOptInBodyWrapper {
      background-color: #f5f5f5;
      border-radius: 14px;
      padding: 16px 24px;
      color: #4a4a4a;
      margin-bottom: 22px;

      & .EmailOptInBodyHeader {
        font-weight: 900;
        font-size: 12px;
        margin: 0 auto 11px;
      }

      & .EmailOptInContentWrapper {
        padding: 0;
        margin-bottom: 0;

        & .ElementWrapper {
          display: flex;
          align-items: center;
          padding-bottom: 18px;

          & .ElementIcon {
            width: 19px;
            height: 19px;
            padding-right: 11px;

            & .LeverageIcon {
              & .LeverageIcon-svg {
                height: 19px;
              }
            }
          }

          & .ElementText {
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
          }
        }
      }

      & label {
        font-size: 12px;
        font-weight: 500;

        & span {
          color: #707070;
          font-size: 10px;
          font-weight: 400;
        }
      }

      & input {
        border: 1px solid #b9b9b9;
        border-radius: 6px;
      }
    }

    & .Agreement {
      display: flex;
      align-items: flex-start;
      margin-bottom: 23px;

      & .Checkbox {
        min-width: auto;
        padding-right: 0;
        margin-right: 0;

        & .CheckboxInput {
          margin-right: 8px;
        }
      }

      & .TextContent {
        font-size: 12px;
        font-weight: 200;
        line-height: 16px;
      }
    }

    & .PillButton {
      font-size: 12px;
    }
  }

  & .DisclaimerTextCard {
    padding: 11px 28px 25px 29px;

    & h2 {
      font-size: 12px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 19px;
    }

    & .DisclaimerTextContainer {
      font-size: 12px;
      border-radius: 14px;
      margin-bottom: 24px;
      padding: 0 10px 10px;
      width: 100%;
      resize: none;
      box-sizing: border-box;
      border: 1px solid #dedede;
      color: #000;
      font-family: inherit;
      max-height: 506px;
      overflow-y: scroll;
      overscroll-behavior-y: contain;
    }
  }

  & .isHidden {
    display: none;
  }
}
