@import './_breakpoints.css';

.HcAvmExplanation {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: left;

  & .LearnMoreAside {
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
    text-decoration: underline;
  }
}

@media (--tablet-screen-and-smaller) {
  .HcAvmExplanation {
    position: static;
    margin: 5px 0;
  }
}
