.FormSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button {
  margin-top: 2rem;
}

.PillButton:disabled {
  cursor: not-allowed;
}
