.ContactNewAgentHeader {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -1px;
  margin-top: 0;
}

.FormPage {
  padding-top: 0;
}

.TermsOfUseCopy {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1778px;
  margin-bottom: 18px;
  margin-top: -7px;
}

.SubmitButton {
  margin-top: 0;
}

.Label {
  font-size: 12px;
}
