.RequestATourForm {
  & fieldset {
    border: 0;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  & legend {
    margin-bottom: 2px;
  }

  & legend,
  & .Label,
  & .CheckboxLabel {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  & .CheckboxInput {
    height: 16px;
    width: 16px;
  }

  & .CheckboxCheck {
    top: 4px;
    left: 9px;
  }

  & .TextInput .InputElement {
    margin-bottom: 0;
  }

  & .OutlineButton {
    font-weight: 400;
    font-size: 12px;
    max-width: calc(50% - 7px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .PillButton {
    height: 40px;
  }

  & .SubmitButton {
    max-width: 100%;
    width: 100%;
  }

  & .InputWrapper {
    width: 100%;
  }
}

.SunIcon,
.SunIcon-svg,
.SunsetIcon,
.SunsetIcon-svg {
  width: 20px;
  margin-right: 8px;
}
