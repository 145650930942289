@value text-color from '../_colors.css';

.LenderCTA {
  height: 36px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LenderCTAButton {
  width: 100%;
  max-width: none;
  font-size: 14px;
  font-weight: 900;
  height: 46px;
  min-height: 46px;
  background-color: #e9e9e9;
  color: text-color;
  border-radius: 35px;
}
