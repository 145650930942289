.modal-container .Modal {
  height: 260px;
  width: 350px;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 26px 0 rgb(0 0 0 / 30%);
  justify-content: center;
  font-family: Avenir;
  color: #626262;
}

.Header {
  height: 25px;
  width: 271px;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
}

.Content {
  display: vertical;
  text-align: center;
}

.MessageBody {
  height: 38px;
  width: 224px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 24px;
  padding-left: 22px;
}

.ButtonWrapper {
  padding-top: 20px;
}

.Button {
  height: 40px;
  width: 200px;
  border-radius: 27.72px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 12px;
}

.Homeowner {
  padding-top: 18px;
  height: 18px;
  width: 257px;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
