@import './_breakpoints.css';
@value text-color, modal-overlay from './_colors.css';
@value rounded-border, box-shadow-long from './_fontsAnimations.css';

.CloseIcon {
  display: none;
  top: 8px;
  right: 10px;

  & :global .element-to-fill {
    fill: #fff;
  }
}

.AuthModal .Modal {
  padding: 0;
  /* Allow top border radius to work correctly.  Doesn't prevent scrolling on when modal overflows
   * the viewport due to `height` not being set on .Modal */
  overflow: hidden;

  & .MobileTopBar {
    background-color: transparent;
  }

  & .DesktopCloseIcon svg :global .element-to-fill,
  & .MobileCloseIcon svg :global .element-to-fill,
  & .MobileCloseIconButton svg :global .element-to-fill {
    fill: white;
  }

  & .DesktopCloseIcon {
    right: 15px;
    top: 12px;
  }
}

.Body {
  padding: 8px 0 0;
  position: relative;
  width: 100vw;
  max-width: 460px;
  box-sizing: border-box;
  margin: 0 auto;
}

.Header {
  color: #fff;
  background: text-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 97px;
  text-align: center;
}

.Title {
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  margin: 0;
}

.Subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.FormContainer {
  top: 0;
  left: 0;
}

@media (--x-small-screen) {
  .CloseIcon {
    display: flex;
    position: absolute;
  }

  .Body {
    max-width: 100vw;
  }

  .AuthModal .Modal {
    overflow: visible !important;
  }
}
