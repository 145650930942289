@import './_breakpoints.css';
@value text-color from './_colors.css';

.Checkmark {
  height: 15px;
  width: 19px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

/* For some reason the Checkmark SVG has a large blank area surrounding it, causing
 * the button that contains it to be huge. I'm making the SVG absolute positioned
 * in order to keep it from taking up room within its container. */

.Checkmark-svg {
  height: 55px;
  width: auto;
  position: absolute;
  top: -15px;
}

.TermsOfUseCopy {
  font-size: 11px;
  line-height: 19px;
  margin-top: 19px;
}

/**
  * To be able to set the color from parcon of the underline of the sign up link
  * we need to remove underline that is set from the SigUpOrLoginLink css
  * and set the text-decoration and text-decoration-color
  * using the labelStyle prop to pass them into the SignUpOrLoginLink component instead
*/
.SignUpOrLoginLink .FlatButton {
  text-decoration: none;
}

.BackLink {
  padding: 20px 0 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
}

.PillButton {
  margin-top: 5px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--small-screen) {
  .BackLink {
    text-align: left;
    padding-top: 190px;
  }
}
