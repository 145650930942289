.Link {
  composes: strip-button-styles from './_common.css';
}

.LoginForm {
  width: 400px;
  max-width: calc(100vw - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;

  & a {
    cursor: pointer;
  }

  & h2 {
    display: none;
  }

  & .Link {
    color: inherit !important;
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .PillButton:disabled {
    cursor: not-allowed;
  }
}

.LoginTitle {
  font-size: 20px;
}

.TogglePasswordVisibilityButton {
  top: 27px;
  height: 40px;
}

.ForgotPasswordLink {
  display: flex;
  margin-top: 0;
  justify-content: flex-end;
}

.CenteredRow {
  composes: auth-row from './_common.css';
}

.SignupLinkRow {
  margin-top: 0;
}

.chevron {
  font-size: 0.6em;
  bottom: 0.1em;
}

.ValidationIndicator {
  display: none;
}

.input {
  text-align: left;
}

.ValidatorInput {
  margin-bottom: 15px;
}
