.Body {
  /* 'min-width: 0;' on the parent allows a flex child to truncate */
  min-width: 0;
}

.SearchTitle {
  /* Truncate title text to match iOS: */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  margin: 0 0 4px;
  font-size: 14px;
  font-weight: 600;
}

.Message {
  font-size: 14px;
  line-height: 20px;
  flex-basis: 3;
}

.IconWrapper {
  margin-right: 20px;
}

.GreenCheckIcon,
.GreenCheckIcon-svg {
  height: 30px;
  width: 30px;
}
