.FindAgentConfirmationText {
  max-width: 300px;
  height: 150px;
  padding: 50px 10px;
  margin: auto;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
}

.PendingAgent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.PendingAgentWithoutCTA {
  margin-bottom: 30px;
}

.PendingAgentHeader {
  margin: 30px 0;
  padding: 0 20px;
  font-weight: 800;
  font-size: 17px;
}

.Photo {
  width: 45px;
  height: 45px;
}

.Name {
  font-weight: 800;
  font-size: 12px;
  margin: 10px 0 5px;
}

.AgentDetail {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 10px;
  margin: 3px 0;
  pointer-events: none;
}

.AgentDetailContainer {
  margin-bottom: 20px;
}

.ChooseAnotherAgentCTA {
  margin-top: 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
}

.CenteredCTA {
  margin: 30px auto;
  width: 100%;
}

.SelectAgentHeader {
  font-weight: 800;
  font-size: 12px;
}

.SelectAgentBody {
  margin-bottom: 80px;
}

.SelectAgentOption {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.SelectAgentOptionInfo {
  margin-left: 10px;
}

.SelectAgentOptionHeader {
  font-weight: 900;
  font-size: 9px;
  margin-bottom: 2px;
}

.SelectAgentOptionName {
  font-weight: 400;
  font-size: 12px;
}

.UseThisAgentCTA {
  margin-left: auto;
  font-weight: 500;
  font-size: 10px;
  width: 104px;
  height: 30px;
  color: #4a4a4a !important;
  background: #e9e9e9 !important;
  border-radius: 36px !important;
}

.GetPairedWithAnAgent {
  width: 100%;
  max-width: unset;
  height: 46px;
  min-height: 46px;
  font-size: 14px;
  margin: 11px auto;
  display: block;
}

.FormElementContainerHorizontal {
  display: flex;
  flex-direction: column;

  & .FormElementWrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.PreferredSellingPriceWrapper,
.TextAreaWrapper,
.FindAgentForm,
.PillButton,
.FormWrapper {
  padding: 0;
  max-width: unset;
  width: 100% !important;
  margin: 0 !important;
}

.FullWidth,
.FindAgentPromptContainer {
  color: #20203f;
  padding: 0 !important;
  margin: 0 0 15px !important;
}

.CloseIcon {
  display: none;
  background: none;
  border: none;
  color: unset;
  padding: 0;
}

.HeroText {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  margin: 15px 0;
  width: 100%;
}

.FindAgentPromptHeader {
  color: #20203f;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
}

.FindAgentPrompt {
  margin-bottom: 3px;
}

.PageWrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.FormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BackLink {
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
  margin: 30px auto 0;
  width: 100%;
}

.SubmitButtonWrapper {
  margin-top: 10px;
}
