.RelayStateActionConfirmationModal {
  & .Modal {
    padding: 28px 26px;
    margin-left: 18px;
    margin-right: 18px;
  }

  & .TextContent {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    max-width: 340px;
    font-weight: 300;
    margin: auto;
  }

  & .TextContent + .TextContent {
    margin-top: 28px;
    max-width: 320px;
  }

  & .ButtonRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .PillButton {
    margin-top: 8px;
    max-width: 70%;
    padding: 13px;
  }
}
